import './App.css';
import { Homepage } from './components/homepage/Homepage';
import { Signin } from './components/auth/Signin';
import { PromptPanel } from './components/promptpanel/PromptPanel';
import { ResumeDetails } from './components/ViewDetails/ResumeDetails';
import { Routes, Route } from 'react-router-dom';
import { ContactUs } from './components/contactus/ContactUs';
import { UploadResume } from './components/uploadresume/UploadResume';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ResumeList } from './components/resumeList/ResumeList';
import { EditResume } from './components/editResume/EditResume';
import { useEffect } from 'react';
import { gapi } from 'gapi-script';

const queryClient = new QueryClient()
const clientId = "501671887563-ableki4g9q5pq8475pvddipf7pkclh62.apps.googleusercontent.com";

function App() {
  useEffect(()=>{
    function start() {
      gapi.client.init({
        clientId: clientId,
        scope: ""
      })
    };
    gapi.load("client:auth2", start);
  });

  // let accessToken = gapi.auth?.getToken().access_token;
  return (
    <QueryClientProvider client={queryClient}>
    <Routes>
      <Route path="/" element={<Homepage />}></Route>
      <Route path="auth" element={<Signin />}></Route>
      <Route path="prompt" element={<PromptPanel />}></Route>
      <Route path="details/:id" element={ <ResumeDetails />}></Route>
      <Route path="contact" element={ <ContactUs />}></Route>
      <Route path="upload" element={ <UploadResume />}></Route>
      <Route path="resume-list" element={ <ResumeList />}></Route>
      <Route path="edit-resume/:id" element={ <EditResume />}></Route>
    </Routes>   
    <ToastContainer />
  </QueryClientProvider>
  );
}

export default App;
