import { Header } from "../common/header/Header";
import { NavLink, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from 'react-toastify';
import { EditTechnology } from "./EditTechnology";
import { fetchWrapper } from "../../util";
import { onErrors } from "../../util";
import { EditExperience } from "./EditExperience";
import { EditProject } from "./EditProject";
const baseURL = process.env.REACT_APP_BASEURL;


export const EditResume = () => {
    let { id } = useParams();
    // Get Data 
    async function resumeData(url) {
        let response;
        try {
            response = await fetch(url, {
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                },
            });
            const candidateData = await response.json();
            // setAllCandidate(candidateData);
            // console.log(candidateData, "dabba", url);
            return candidateData;
        }
        catch (error) {
            throw (new Error("API went Wrong"))
        }
    }
    const { isPending: candidatePending, data: candidateData, } = useQuery({
        queryKey: ['candidates', id],
        queryFn: () => resumeData(`${baseURL}/candidates/${id}`),
    })
    const { isPending: profileSummeryPending, data: profileSummeryData, isError: profileSummeryError } = useQuery({
        queryKey: ['profileSummery', id],
        queryFn: () => resumeData(`${baseURL}/candidate-summaries/candidate/${id}`),
    })

    const { isPending: technologyPending, data: technologyData, isError: technologyError } = useQuery({
        queryKey: ['technology', id],
        queryFn: () => resumeData(`${baseURL}/candidate-technology/candidate/${id}`),
    })

    const { isPending: experiencePending, data: experienceData, isError: experienceError } = useQuery({
        queryKey: ['experience', id],
        queryFn: () => resumeData(`${baseURL}/candidate-experience/candidate/${id}`),
    })
    const { isPending: projectPending, data: projectData, isError: projectError } = useQuery({
        queryKey: ['project', id],
        queryFn: () => resumeData(`${baseURL}/candidate-projects/candidate/${id}`),
    })
    // const extractDate = (dateTimeString) => dateTimeString?.split('T')[0];

    // Put Data 

    function fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    }


    const [formSubmitted, setFormSubmitted] = useState(false);
    const [summeryFormSubmitted, setSummeryformSubmitted] = useState(false);
    const [candidateId, setCandidateId] = useState(null);
    const [updateExisting, setUpdateExisting] = useState(false);



    const { register: regUserInfo, handleSubmit: hSUserInfo, formState: { errors: errorsUserInfo } } = useForm();
    const { register: regProfileSummery, handleSubmit: hSProfileSummery, reset: resetProfileSummery, formState: { errors: errorsProfileSummery } } = useForm();


    // Candidate Info 
    const { mutate: candidateInfo } = useMutation({
        mutationFn: async (data) => {
            const response = await fetch(`${baseURL}/candidates/${id}`, {
                method: "PUT",
                body: JSON.stringify({ ...data, candidateId }),
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                    "Content-Type": "application/json",
                },
            });
            if (response.ok) {
                const responseData = await response.json();
                const uploadedCandidateId = responseData.id;
                setCandidateId(uploadedCandidateId);
            }
            else {
                // Handle error
                console.error("Error uploading user info");
            }
        },
        onSuccess: () => {
            toast.success("Updated Successfully")
        },
        onError: () => {
            toast.error("There is an error")
        },
    });


    // Candidate Profile summery 
    const { mutate: mutateProfileSummery } = useMutation({
        mutationFn: (data5) => {
            return fetchWrapper(`${baseURL}/candidate-summaries/${data5.id}`, {
                method: "PUT",
                body: JSON.stringify({ ...data5 }),
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                    "Content-Type": "application/json",
                },
            })
        },
        onSuccess: () => {
            toast.success("Updated Successfully")
        },
        onError: () => {
            toast.error("There is an error")
        },
    })





    const onFormSubmit = async (data) => {
        if (data.profilePic.length)
            data.profilePic = await fileToBase64(data.profilePic[0]);
        else {
            const { profilePic, ...rest } = data;
            data = rest;
        }

        candidateInfo(data)
        setFormSubmitted(true);
    };

    const onProfileSummerySubmit = (data5) => {
        data5.candidateInfo = { id: candidateId }
        // console.log(data5, "Tech");
        mutateProfileSummery(data5);
        setSummeryformSubmitted(true);
    };

    const handleDelete = async (id) => {
        await fetch(`${baseURL}/candidates/${id}`, {
            method: 'DELETE',
            headers: {
                "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
            },
        });
    };

    const handleDelete2 = async (id) => {
        await fetch(`${baseURL}/candidate-summaries/${id}`, {
            method: 'DELETE',
            headers: {
                "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
            },
        });
    };



    return <section className="editResume">
        <Header />

        {/* Candidate Info  */}
        {
            candidatePending ? "Data Is loading" : <>
                <div className="container">
                    <form onSubmit={hSUserInfo(onFormSubmit, errorsUserInfo)}>
                        <div className="row">
                            <div className="col-12">
                                <h3>Candidate Details</h3>
                            </div>
                            <div className="col-md-2">
                                <div className="profileImage">
                                    <div className="inputField">
                                        <label htmlFor="" className="d-block">Profile Image
                                        </label>
                                        <img src={candidateData.profilePic} alt="" className="w-100" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="inputField">
                                    <label htmlFor="">Change Image</label>
                                    <input type="file" name="profilePic" className="form-control" {...regUserInfo('profilePic')} />

                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="inputField">
                                    <label htmlFor="">Candidate Name</label>
                                    <input type="text" name="name" defaultValue={candidateData.name} placeholder="Enter Candidate Name" className="form-control"  {...regUserInfo('name')} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="inputField">
                                    <label htmlFor="">Candidate Post</label>
                                    <input type="text" name="designation" defaultValue={candidateData.designation} placeholder="e.g Front End Developer" className="form-control" {...regUserInfo('designation')} />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="inputField">
                                    <label htmlFor="">Year of Experience</label>
                                    <input type="text" name="experience" placeholder="e.g 5" defaultValue={candidateData.experience} className="form-control" {...regUserInfo('experience')} required />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="inputField">
                                    <label htmlFor="">About Candidate</label>
                                    <textarea name="introduction" defaultValue={candidateData.introduction} id="" cols="30" rows="7" className="form-control" placeholder="About Candidate" {...regUserInfo('introduction')} required></textarea>
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <button className="btn btn-theme me-3" type="submit">Update</button>
                                <button className="btn btn-danger" onClick={() => handleDelete(candidateData.id)}>Delete</button>

                            </div>
                        </div>

                    </form>
                </div>
            </>
        }


        {/* Profile Summery */}

        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h3>Profile Summary</h3>
                </div>
                <div className="col-12">
                    {
                        profileSummeryPending ? "Data loading" : profileSummeryError ? "There is some error" : profileSummeryData.map((e) => {
                            return <form onSubmit={hSProfileSummery(onProfileSummerySubmit, onErrors)}>
                                <div className="col-12">
                                    <div className="addProfileBox">
                                        <input type="text" name="id" defaultValue={e.id} className="form-control d-none" {...regProfileSummery('id')} />
                                        <textarea defaultValue={e.summary} cols="30" rows="7" name="summary" placeholder="Add a paragraph saperated by '.'" className="form-control" {...regProfileSummery('summary')} required />
                                        <button className="btn btn-theme mt-4 me-3" type="submit">Update</button>
                                        <button className="btn btn-danger mt-4" onClick={() => handleDelete2(e.id)}>Delete</button>
                                    </div>
                                </div>

                            </form>
                        })
                    }

                </div>
            </div>
        </div>


        {/* Skills  */}
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h3>Add Skills</h3>
                </div>
                <div className="col-12">
                    {
                        technologyPending ? " Data Is loading" : technologyError ? "There is some error" : technologyData.map((e) => {
                            // console.log(e, "Parik")
                            return <EditTechnology id={e.id} header={e.header} technology={e.technology} />
                        })}

                </div>
            </div>
        </div>

        {/* Exxperience  */}
        <div className="container mt-5">
            <div className="row">
                <div className="col-12">
                    <h3>Add Experience</h3>
                </div>
                <div className="col-12">
                    {
                        experiencePending ? "Data is loading" : experienceError ? "There is some error" : experienceData.map((e) => {
                            return <EditExperience id={e.id} designation={e.designation} company={e.company} startDate={e.startDate} endDate={e.endDate} summary={e.summary} />
                        })
                    }

                </div>
            </div>
        </div>

        {/* Project  */}
        <div className="container mt-5">
            <div className="row">
                <div className="col-12 ">
                    <h4>Add Project</h4>
                </div>
                <div className="col-12">
                    {
                        projectPending ? "Data is loading" : projectError ? "There is some error" : projectData.map((e) => {
                            return <EditProject id={e.id} projectTitle={e.projectTitle} role={e.role} duration={e.duration} details={e.details} technology={e.technology} />
                        })
                    }

                </div>
            </div>
        </div>
    </section>
}