import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { fetchWrapper } from "../../util";
import { onErrors } from "../../util";
const baseURL = process.env.REACT_APP_BASEURL;

export const EditExperience = ({ id, designation, company, startDate, endDate, summary }) => {
    const { register: regExperience, handleSubmit: hSUserExperience, reset: resetExperience, formState: { errors: errorsExperience } } = useForm();
    const extractDate = (dateTimeString) => dateTimeString?.split('T')[0];


    // Caandidate Experience 
    const { mutate: experience } = useMutation({
        mutationFn: (experiencedata) => {
            return fetchWrapper(`${baseURL}/candidate-experience/${experiencedata.id}`, {
                method: "PUT",
                body: JSON.stringify({ ...experiencedata }),
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                    "Content-Type": "application/json",
                },
                onSuccess: () => {
                    toast.success("Created Successfully")
                },
                onError: () => {
                    toast.error("There is an error")
                },
            })
        },
        onSuccess: () => {
            toast.success("Created Successfully")
        },
        onError: () => {
            toast.error("There is an error")
        },
    })

    const handleDelete = async (id) => {
        await fetch(`${baseURL}/candidate-experience/${id}`, {
            method: 'DELETE',
            headers: {
                "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
            },
        });
    };


    const onExperienceSubmit = (experiencedata) => {
        // console.log(experiencedata);
        experience(experiencedata);
    };


    return <form key={id} onSubmit={hSUserExperience(onExperienceSubmit, onErrors)}>
        <div className="row">
            <div className="col-12">
                <input className="form-control d-none" type="text" name="id" defaultValue={id} {...regExperience('id')} />

            </div>
            <div className="col-md-4">
                <div className="inputField">
                    <label htmlFor="">Post</label>
                    <input type="text" defaultValue={designation} placeholder="Enter Post" name="designation" className="form-control" {...regExperience('designation')} />
                </div>
            </div>
            <div className="col-md-4">
                <div className="inputField">
                    <label htmlFor="">Comapny Name</label>
                    <input type="text" defaultValue={company} name="company" placeholder="Enter Comapny Name" className="form-control" {...regExperience('company')} />
                </div>
            </div>
            <div className="col-md-4">
                <div className="row">
                    <div className="col-md-6">
                        <div className="inputField">
                            <label htmlFor="">Start Date</label>
                            <input type="date" defaultValue={extractDate(startDate)} name="startDate" placeholder="e.g YYYY-MM-DD" className="form-control me-3" {...regExperience('startDate')} />

                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="inputField">
                            <label htmlFor="">End Date</label>
                            <input type="date" defaultValue={extractDate(endDate)} name="endDate" placeholder="e.g YYYY-MM-DD" className="form-control" {...regExperience('endDate')} />
                        </div>

                    </div>
                </div>

            </div>
            <div className="col-12">
                <div className="inputField">
                    <label htmlFor="">Profile Summery</label>
                    <textarea name="summary" defaultValue={summary} id="" cols="30" rows="7" className="form-control" placeholder="About Candidate" {...regExperience('summary')}></textarea>
                </div>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <button className="btn btn-theme me-3" type="submit">Update</button>
                <button className="btn btn-danger" onClick={() => handleDelete(id)}>Delete</button>
            </div>
        </div>
    </form>
}