import { useMutation } from "@tanstack/react-query";
import { Header } from "../common/header/Header";
import "./UploadResume.scss";
import { useForm, Controller } from "react-hook-form";
import { useState } from "react";
import success from "../../assets/success.svg";
import { toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import skillsOption from "../../data/skills.json";

// import { useQuery } from "@tanstack/react-query";
const animatedComponents = makeAnimated();
const baseURL = process.env.REACT_APP_BASEURL;

export const UploadResume = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [summeryFormSubmitted, setSummeryformSubmitted] = useState(false);
  const [candidateId, setCandidateId] = useState(null);
  const [updateExisting, setUpdateExisting] = useState(false);

  const {
    register: regUserInfo,
    handleSubmit: hSUserInfo,
    formState: { errors: errorsUserInfo },
  } = useForm();
  const {
    register: regExperience,
    handleSubmit: hSUserExperience,
    reset: resetExperience,
    formState: { errors: errorsExperience },
  } = useForm();
  const {
    register: regUserProjects,
    handleSubmit: hSUserProjects,
    reset: resetUserProjects,
    formState: { errors: errorsProjects },
  } = useForm();
  const {
    register: regUserTechnology,
    handleSubmit: hSUserTechnology,
    reset: resetUserTechnology,
    formState: { errors: errorsUserTechnology },
    control: userControl,
  } = useForm();
  const {
    register: regProfileSummery,
    handleSubmit: hSProfileSummery,
    reset: resetProfileSummery,
    formState: { errors: errorsProfileSummery },
  } = useForm();

  // Candidate Info
  const { mutate: candidateInfo } = useMutation({
    mutationFn: async (data) => {
      const response = await fetch(`${baseURL}/candidates`, {
        method: "POST",
        body: JSON.stringify({ ...data, candidateId }),
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const responseData = await response.json();
        const uploadedCandidateId = responseData.id;
        setCandidateId(uploadedCandidateId);
      } else {
        // Handle error
        console.error("Error uploading user info");
      }
    },
  });

  const fetchWrapper = async (url, params) => {
    const response = await fetch(url, params);
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(errorMessage || "Failed to fetch");
    }
    return response.json();
  };

  // Caandidate Experience
  const { mutate: experience } = useMutation({
    mutationFn: (experiencedata) => {
      return fetchWrapper(`${baseURL}/candidate-experience`, {
        method: "POST",
        body: JSON.stringify({ ...experiencedata }),
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
          "Content-Type": "application/json",
        },
        onSuccess: () => {
          toast.success("Created Successfully");
        },
        onError: () => {
          toast.error("There is an error");
        },
      });
    },
    onSuccess: () => {
      toast.success("Created Successfully");
    },
    onError: () => {
      toast.error("There is an error");
    },
  });

  // Candidate Projects
  const { mutate: projects } = useMutation({
    mutationFn: (data3) => {
      return fetchWrapper(`${baseURL}/candidate-projects`, {
        method: "POST",
        body: JSON.stringify({ ...data3 }),
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: () => {
      toast.success("Created Successfully");
    },
    onError: () => {
      toast.error("There is an error");
    },
  });

  // Candidate Technology
  const { mutate: mutateTechnology } = useMutation({
    mutationFn: (data4) => {
      return fetchWrapper(`${baseURL}/candidate-technology`, {
        method: "POST",
        body: JSON.stringify({ ...data4 }),
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: () => {
      toast.success("Created Successfully");
    },
    onError: () => {
      toast.error("There is an error");
    },
  });

  // Candidate Profile summery
  const { mutate: mutateProfileSummery } = useMutation({
    mutationFn: (data5) => {
      return fetchWrapper(`${baseURL}/candidate-summaries`, {
        method: "POST",
        body: JSON.stringify({ ...data5 }),
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: () => {
      toast.success("Created Successfully");
    },
    onError: () => {
      toast.error("There is an error");
    },
  });

  const onFormSubmit = async (data) => {
    data.profilePic = await fileToBase64(data.profilePic[0]);
    // console.log(data);
    candidateInfo(data);
    setFormSubmitted(true);
  };

  const onExperienceSubmit = (experiencedata) => {
    experiencedata.candidateInfo = { id: candidateId };
    // console.log(experiencedata);
    experience(experiencedata);
    resetExperience();
  };

  const onProjectSubmit = (data3) => {
    data3.candidateInfo = { id: candidateId };
    // console.log(data3);
    projects(data3);
    resetUserProjects();
  };

  const onTechnologySubmit = (data4) => {
    data4.candidateInfo = { id: candidateId };
    const selectedSkills = data4.skills.map(option => option.value);
    const skillsToString = selectedSkills.join(', ');
    data4.technology = skillsToString
    delete data4.skills;
    mutateTechnology(data4);
    resetUserTechnology();
  };

  const onProfileSummerySubmit = (data5) => {
    data5.candidateInfo = { id: candidateId };
    // console.log(data5, "Tech");
    mutateProfileSummery(data5);
    resetProfileSummery();
    setSummeryformSubmitted(true);
  };

  function fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const onErrors = (errors) => {
    console.error(errors);
  };

  async function resumeData(url) {
    let response;
    try {
      response = await fetch(url, {
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
        },
      });
      const candidateData = await response.json();
      return candidateData;
    } catch (error) {
      throw new Error("API went Wrong");
    }
  }

  return (
    <section className="UploadResume">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Upload Resume</h2>
            <h2>
              <button
                className="btn btn-main"
                onClick={() => setUpdateExisting((p) => !p)}
              >
                Add Details to Resume
              </button>
            </h2>
          </div>
          <div className="col-12">
            {/* User basic Info  */}
            {formSubmitted ? (
              <div className="row mt-4">
                <div className="col-12">
                  <div className="successMessage">
                    <img src={success} alt="" />
                    <p className="mb-0">User Information is saved</p>
                  </div>
                </div>
              </div>
            ) : updateExisting ? (
              <input
                type="text"
                placeholder="Enter Resume Id"
                className="form-control mt-5"
                onChange={(e) => {
                  setCandidateId(e.target.value);
                }}
                value={candidateId}
              />
            ) : (
              <form onSubmit={hSUserInfo(onFormSubmit, onErrors)}>
                <div className="row mt-4">
                  <div className="col-12">
                    <div className="inputField">
                      <label htmlFor="">Upload profile Image</label>
                      <input
                        type="file"
                        name="profilePic"
                        id="uploadImage"
                        className="form-control"
                        {...regUserInfo("profilePic", {
                          required: "Profile image is required",
                        })}
                      />
                      {errorsUserInfo.profilePic && (
                        <p className="text-danger">
                          {errorsUserInfo.profilePic.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputField">
                      <label htmlFor="candName">Candidate Name</label>
                      <input
                        type="text"
                        id="candName"
                        name="name"
                        placeholder="Enter Candidate Name"
                        className="form-control"
                        {...regUserInfo("name", {
                          required: "Candidate Name is required",
                        })}
                      />
                      {errorsUserInfo.name && (
                        <p className="text-danger">
                          {errorsUserInfo.name.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputField">
                      <label htmlFor="">Candidate Post</label>
                      <input
                        type="text"
                        name="designation"
                        placeholder="e.g Front End Developer"
                        className="form-control"
                        {...regUserInfo("designation", {
                          required: "Candidate Post is required",
                        })}
                      />
                      {errorsUserInfo.designation && (
                        <p className="text-danger">
                          {errorsUserInfo.designation.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="inputField">
                      <label htmlFor="">Year of Experience</label>
                      <input
                        type="text"
                        name="experience"
                        placeholder="e.g 5"
                        className="form-control"
                        {...regUserInfo("experience", {
                          required: "Experience is required",
                        })}
                      />
                      {errorsUserInfo.experience && (
                        <p className="text-danger">
                          {errorsUserInfo.experience.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="inputField">
                      <label htmlFor="">About Candidate</label>
                      <textarea
                        name="introduction"
                        id=""
                        cols="30"
                        rows="3"
                        className="form-control"
                        placeholder="About Candidate"
                        {...regUserInfo("introduction", {
                          required: "Candidate detail is required",
                        })}
                      ></textarea>
                      {errorsUserInfo.introduction && (
                        <p className="text-danger">
                          {errorsUserInfo.introduction.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <button className="btn btn-theme" type="submit">
                      Update Info
                    </button>
                  </div>
                </div>
              </form>
            )}

            {/* Profile summery  */}
            {summeryFormSubmitted ? (
              <div className="row mt-4">
                <div className="col-12">
                  <div className="successMessage">
                    <img src={success} alt="" />
                    <p className="mb-0">Profile Summery is saved</p>
                  </div>
                </div>
              </div>
            ) : (
              <form
                onSubmit={hSProfileSummery(onProfileSummerySubmit, onErrors)}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="inputField">
                      <label htmlFor="">Profile Summery</label>
                      <div className="addProfileBox">
                        <textarea
                          cols="30"
                          rows="4"
                          name="summary"
                          placeholder="Add a paragraph saperated by '.'"
                          className="form-control"
                          {...regProfileSummery("summary", {
                            required: "Candidate Summery is required",
                          })}
                        />
                        <button className="btn btn-theme" type="submit">
                          Add
                        </button>
                      </div>
                      {errorsProfileSummery.summary && (
                        <p className="text-danger">
                          {errorsProfileSummery.summary.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}

            <form onSubmit={hSUserTechnology(onTechnologySubmit, onErrors)}>
              <div className="col-12">
                <div className="inputField">
                  <label htmlFor="">Add Skills</label>
                  <div className="addProfileBox">
                    {/* <div className="flex-1">
                      <input
                        type="text"
                        name="header"
                        placeholder="Heading"
                        className="form-control "
                        {...regUserTechnology("header", {
                          required: "Heading is required",
                        })}
                      />
                      {errorsUserTechnology.header && (
                        <p className="text-danger">
                          {errorsUserTechnology.header.message}
                        </p>
                      )}
                    </div> */}
                    {/* <div className="flex-1">
                                        <input type="text" name="technology" placeholder="Add technology saperated with comma (,)" className="form-control " {...regUserTechnology('technology', { required: 'Technology is required' })} />
                                        {errorsUserTechnology.technology && <p className="text-danger">{errorsUserTechnology.technology.message}</p>}

                                    </div> */}
                    <div className="flex-1">
                      {/* <Controller
                        control={userControl}
                        name="skills"
                        render={({ onChange, value, name }) => (
                          <Select
                            name={name}
                            className="basic-single"
                            classNamePrefix="select"
                            components={animatedComponents}
                            options={skillsOption}
                            value={skillsOption.filter((c) =>
                              value?.includes(c.value)
                            )}
                            // onChange={(val) =>
                            //   onChange(val?.map((c) => c.value))
                            // }
                            isClearable={true}
                            isMulti
                          />
                        )}
                      /> */}

                      <Controller
                        control={userControl}
                        name="skills"
                        rules={{ required: "Skills is required!" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            components={animatedComponents}
                            isMulti
                            options={skillsOption}
                            placeholder="Select Skills"
                          />
                        )}
                      />
                      {errorsUserTechnology.skills && (
                        <p className="text-danger">
                          {errorsUserTechnology.skills.message}
                        </p>
                      )}
                    </div>

                    <div>
                      <button className="btn btn-theme" type="submit">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form onSubmit={hSUserExperience(onExperienceSubmit, onErrors)}>
              <div className="row">
                <div className="col-12 mt-5">
                  <h4>Add Experience</h4>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="inputField">
                        <label htmlFor="">Post</label>
                        <input
                          type="text"
                          placeholder="Enter Post"
                          name="designation"
                          className="form-control"
                          {...regExperience("designation", {
                            required: "Post is required",
                          })}
                        />
                        {errorsExperience.designation && (
                          <p className="text-danger">
                            {errorsExperience.designation.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="inputField">
                        <label htmlFor="">Comapny Name</label>
                        <input
                          type="text"
                          name="company"
                          placeholder="Enter Comapny Name"
                          className="form-control"
                          {...regExperience("company", {
                            required: "Company name  is required",
                          })}
                        />
                        {errorsExperience.company && (
                          <p className="text-danger">
                            {errorsExperience.company.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="inputField">
                        <div className="d-flex">
                          <div className="flex-1 me-3">
                            <label htmlFor="">Joining Date</label>
                            <input
                              type="date"
                              name="startDate"
                              placeholder="e.g YYYY-MM-DD"
                              className="form-control "
                              {...regExperience("startDate", {
                                required: "Joining date is required",
                              })}
                            />
                            {errorsExperience.startDate && (
                              <p className="text-danger">
                                {errorsExperience.startDate.message}
                              </p>
                            )}
                          </div>
                          <div className="flex-1">
                            <label htmlFor="">Leaving Date</label>
                            <input
                              type="date"
                              name="endDate"
                              placeholder="e.g YYYY-MM-DD"
                              className="form-control"
                              {...regExperience("endDate", {
                                required: "End date is required",
                              })}
                            />
                            {errorsExperience.endDate && (
                              <p className="text-danger">
                                {errorsExperience.endDate.message}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="inputField">
                        <label htmlFor="">Profile summary</label>
                        <textarea
                          name="summary"
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control"
                          placeholder="About Candidate"
                          {...regExperience("summary", {
                            required: "Profile summary is required",
                          })}
                        ></textarea>
                        {errorsExperience.summary && (
                          <p className="text-danger">
                            {errorsExperience.summary.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <button className="btn btn-main" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <form onSubmit={hSUserProjects(onProjectSubmit, onErrors)}>
              <div className="row">
                <div className="col-12 mt-5">
                  <h4>Add Project</h4>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="inputField">
                        <label htmlFor="">Projects</label>
                        <input
                          type="text"
                          name="projectTitle"
                          placeholder="Project Name"
                          className="form-control"
                          {...regUserProjects("projectTitle", {
                            required: "Project Name is required",
                          })}
                        />
                        {errorsProjects.projectTitle && (
                          <p className="text-danger">
                            {errorsProjects.projectTitle.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="inputField">
                        <label htmlFor="">Role</label>
                        <input
                          type="text"
                          name="role"
                          placeholder="Enter Role"
                          className="form-control"
                          {...regUserProjects("role", {
                            required: "Role is required",
                          })}
                        />
                        {errorsProjects.role && (
                          <p className="text-danger">
                            {errorsProjects.role.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="inputField">
                        <label htmlFor="">Duration</label>
                        <input
                          type="text"
                          name="duration"
                          placeholder="In Months like 4 or 6"
                          className="form-control"
                          {...regUserProjects("duration", {
                            required: "Duration is required",
                          })}
                        />
                        {errorsProjects.duration && (
                          <p className="text-danger">
                            {errorsProjects.duration.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="inputField">
                        <label htmlFor="">Enter Details</label>
                        <textarea
                          name="details"
                          id=""
                          cols="30"
                          rows="3"
                          className="form-control"
                          placeholder="Add a paragraph saperated by '.'"
                          {...regUserProjects("details", {
                            required: "Details are required",
                          })}
                        ></textarea>
                        {errorsProjects.details && (
                          <p className="text-danger">
                            {errorsProjects.details.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="inputField">
                        <label htmlFor="">Add Technology used</label>
                        <div className="inputFileld">
                          <input
                            type="text"
                            name="technology"
                            placeholder="saperate with comma (,)"
                            className="form-control"
                            {...regUserProjects("technology", {
                              required: "Technology is required",
                            })}
                          />
                          {errorsProjects.technology && (
                            <p className="text-danger">
                              {errorsProjects.technology.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-12">
                      <button className="btn btn-main" type="submit">
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};