import { Header } from "../common/header/Header";
import "./ResumeDetails.scss";
import { NavLink, useParams } from "react-router-dom";
import left from "../../assets/LeftCircularDark.svg";
import hire from "../../assets/hire.svg";
import download from "../../assets/download.svg";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../Errors/Loading";
import { Accessing } from "../Errors/Accessing";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useRef } from "react";
const baseURL = process.env.REACT_APP_BASEURL;

const currentDate = new Date(); // Get the current date
const formattedCurrentDate = currentDate.toISOString().split('T')[0];

export const ResumeDetails = () => {
    let { id } = useParams();
    const resumeRef = useRef(null);
    const { isPending: candidatePending, data: candidateData } = useQuery({
        queryKey: ['candidates', id],
        queryFn: () => resumeData(`${baseURL}/candidates/${id}`),
    });
    const { isPending: profileSummeryPending, data: profileSummeryData, isError: profileSummeryError } = useQuery({
        queryKey: ['profileSummery', id],
        queryFn: () => resumeData(`${baseURL}/candidate-summaries/candidate/${id}`),
    });
    const { isPending: technologyPending, data: technologyData, isError: technologyError } = useQuery({
        queryKey: ['technology', id],
        queryFn: () => resumeData(`${baseURL}/candidate-technology/candidate/${id}`),
    });
    const { isPending: experiencePending, data: experienceData, isError: experienceError } = useQuery({
        queryKey: ['experience', id],
        queryFn: () => resumeData(`${baseURL}/candidate-experience/candidate/${id}`),
    });
    const { isPending: projectPending, data: projectData, isError: projectError } = useQuery({
        queryKey: ['project', id],
        queryFn: () => resumeData(`${baseURL}/candidate-projects/candidate/${id}`),
    });

    const extractDate = (dateTimeString) => dateTimeString?.split('T')[0];
    const extractMonthAndYear = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        return `${month} ${year}`;
    };

    async function resumeData(url) {
        let response;
        try {
            response = await fetch(url, {
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                },
            });
            const candidateData = await response.json();
            return candidateData;
        } catch (error) {
            throw new Error("API went Wrong");
        }
    }

    const downloadResumeAsPDF = async () => {
        const input = resumeRef.current;
        if (!input) return; // Ensure the element exists

        // Clone the input element
        const clonedInput = input.cloneNode(true);

        // Replace the class 'container' with 'container-fluid' in the cloned input element
        const containerElements = clonedInput.querySelectorAll('.container');
        containerElements.forEach(containerElement => {
            containerElement.classList.remove('container');
            containerElement.classList.add('container-fluid');
        });

        const inputHeight = input.scrollHeight;
        const inputWidth = input.scrollWidth;

        // Configure html2canvas options
        const canvas = await html2canvas(input, {
            scale: 2, // Increase scale to improve quality
            useCORS: true, // Enable cross-origin resource sharing if needed
            ignoreElements: (element) => {
                // Ignore the header and footer elements
                return element.id === 'header' || element.id === 'footer' || element.id === 'leftNav' || element.id === 'search';
            }
        });

        const imgData = canvas.toDataURL('image/jpeg', 0.5); // Adjust the quality parameter (0.0 - 1.0)

        // Set maximum dimensions for the image
        const maxWidth = 595.28; // A4 size width in points (210 mm)
        const maxHeight = 841.89; // A4 size height in points (297 mm)
        // Calculate the aspect ratio
        const aspectRatio = inputWidth / inputHeight;

        // Calculate new dimensions while maintaining the aspect ratio
        let newWidth = inputWidth;
        let newHeight = inputHeight;

        if (inputWidth > maxWidth) {
            newWidth = maxWidth;
            newHeight = maxWidth / aspectRatio;
        }

        if (newHeight > maxHeight) {
            newHeight = maxHeight;
            newWidth = maxHeight * aspectRatio;
        }

        const pdf = new jsPDF({
            orientation: newWidth > newHeight ? 'landscape' : 'portrait',
            unit: 'pt',
            format: [newWidth, newHeight],
        });

        pdf.addImage(imgData, 'JPEG', 0, 0, newWidth, newHeight);
        pdf.save("resume.pdf");
    };

    return (
        <section className="resumeDetails" id="resumeDetails" ref={resumeRef}>
            <Header />
            <div className="container">
                <div className="row">
                    {candidatePending ? (
                        <Accessing />
                    ) : (
                        <>
                            <div className="col-12">
                                <div className="CandidateTirle">
                                    <NavLink to="/" id="leftNav">
                                        <img src={left} alt="" />
                                    </NavLink>
                                    <h3 className="mb-0">{candidateData.name}</h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="profileDetails">
                                    <div className="image">
                                        <img src={candidateData.profilePic} alt="" className="profilePic" />
                                        <p className="mb-0">{candidateData.experience}</p>
                                    </div>
                                    <div className="detailBox">
                                        <h3>{candidateData.name}</h3>
                                        <h4>{candidateData.designation}</h4>
                                        <p>{candidateData.introduction}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className="col-12 mt-5">
                        <div className="basicprofile">
                            <h2>Profile Summary</h2>
                            {profileSummeryPending ? (
                                <Accessing />
                            ) : profileSummeryError ? (
                                "There is some error"
                            ) : (
                                profileSummeryData.map((e) => (
                                    <ul key={e.id}>
                                        {e.summary.split(".").slice(0, -1).map((points, index) => (
                                            <li key={index}>{points}.</li>
                                        ))}
                                    </ul>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="skillBox">
                            {technologyPending ? (
                                <Accessing />
                            ) : technologyError ? (
                                "There is some error"
                            ) : (
                                technologyData.map((e) => (
                                    <div className="skillRow" key={e.id}>
                                        <h3>{e.header}</h3>
                                        <div className="skillChips">
                                            {e.technology.split(",").map((tech, index) => (
                                                <div className="chip" key={index}>
                                                    {tech}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="basicprofile">
                            {candidatePending ? (
                                <Accessing />
                            ) : (
                                <>
                                    <h2>
                                        Experience <span> ~ {candidateData.experience}</span>
                                    </h2>
                                </>
                            )}
                            {experiencePending ? (
                                <Accessing />
                            ) : experienceError ? (
                                "No data Found"
                            ) : (
                                experienceData.map((e) => {
                                    // Function to extract month and year from a date string
                                    const extractMonthAndYear = (dateString) => {
                                        return dateString.split("-").slice(0, 2).join("-");
                                    };

                                    // Create a new Date object representing today's date
                                    let today = new Date();

                                    // Subtract 6 months from today's date
                                    today.setMonth(today.getMonth() - 6);

                                    // Format the date to a string (optional)
                                    let dd = String(today.getDate()).padStart(2, "0");
                                    let mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
                                    let yyyy = today.getFullYear();

                                    let sixMonthsAgo = yyyy + "-" + mm + "-" + dd;

                                    // Parse the end date from the experience data
                                    const endDate = new Date(e.endDate);
                                    const sixMonthsAgoDate = new Date(sixMonthsAgo);

                                    // Check if the end date is greater than six months ago
                                    const endDateIsNearest = endDate > sixMonthsAgoDate;

                                    return (
                                        <div className="previousCompany" key={e.id}>
                                            <div className="degination">
                                                <h3>{e.designation}</h3>
                                                <h3 className="text-dark">
                                                    {extractMonthAndYear(e.startDate)} -{" "}
                                                    {endDateIsNearest ? "Present" : extractMonthAndYear(e.endDate)}
                                                </h3>
                                            </div>
                                            <h4>{e.company}</h4>
                                            <p>{e.summary}</p>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="basicprofile">
                            <h2>Projects</h2>
                            {projectPending ? (
                                <Accessing />
                            ) : projectError ? (
                                "There is some error"
                            ) : (
                                projectData?.map((e) => (
                                    <div className="projectDetails" key={e.id}>
                                        <h3>Project : {e.projectTitle}</h3>
                                        <ul>
                                            <li>Role: {e.role}</li>
                                        </ul>
                                        <h3>Details</h3>
                                        <ul>
                                            {e.details.split(".").slice(0, -1).map((detail, index) => (
                                                <li key={index}>{detail}.</li>
                                            ))}
                                        </ul>
                                        <h3>Technology</h3>
                                        <p>{e.technology}</p>
                                        <h3>Duration</h3>
                                        <p>{e.duration} Months</p>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="col-12 mb-5" id="footer">
                        <NavLink to="/contact">
                            <button className="btn btn-theme me-3">
                                Hire Now <img src={hire} alt="" />
                            </button>
                        </NavLink>
                        <button className="btn btn-theme" onClick={downloadResumeAsPDF}>
                            Download Resume <img src={download} alt="" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};
