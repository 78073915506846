export const fetchWrapper = async (url, params) => {
    const response = await fetch(url, params)
    if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || 'Failed to fetch');
    }
    return response.json();
};


export  const onErrors = (errors) => {
    console.error(errors);
};