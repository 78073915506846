import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { fetchWrapper } from "../../util";
import { onErrors } from "../../util";
import "./EditResume.scss";
const baseURL = process.env.REACT_APP_BASEURL;


export const EditProject = ({ id, projectTitle, role, duration, details, technology }) => {

    const { register: regUserProjects, handleSubmit: hSUserProjects, reset: resetUserProjects, formState: { errors: errorsProjects } } = useForm();


    // Candidate Projects 
    const { mutate: projects } = useMutation({
        mutationFn: (data3) => {
            return fetchWrapper(`${baseURL}/candidate-projects/${data3.id}`, {
                method: "PUT",
                body: JSON.stringify({ ...data3 }),
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                    "Content-Type": "application/json",
                },
            })
        },
        onSuccess: () => {
            toast.success("Updated Successfully")
        },
        onError: () => {
            toast.error("No data Found")
        },
    })

    const handleDelete = async (id) => {
        await fetch(`${baseURL}/candidate-projects/${id}`, {
            method: 'DELETE',
            headers: {
                "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
            },
        });
    };

    const onProjectSubmit = (data3) => {
        // console.log(data3);
        projects(data3);
    };


    return <form onSubmit={hSUserProjects(onProjectSubmit, onErrors)}>
        <div className="row">
            <div className="col-12">
                <input type="text" name="id" className="form-control d-none" value={id} {...regUserProjects('id')} />
            </div>
            <div className="col-md-4">
                <div className="inputField">
                    <label htmlFor="">Projects</label>
                    <input type="text" defaultValue={projectTitle} name="projectTitle" placeholder="Project Name" className="form-control" {...regUserProjects('projectTitle')} />
                </div>
            </div>
            <div className="col-md-4">
                <div className="inputField">
                    <label htmlFor="">Role</label>
                    <input type="text" defaultValue={role} name="role" placeholder="Enter Role" className="form-control" {...regUserProjects('role')} />
                </div>
            </div>
            <div className="col-md-4">
                <div className="inputField">
                    <label htmlFor="">Duration</label>
                    <input type="text" defaultValue={duration} name="duration" placeholder="In Months like 4 or 6" className="form-control" {...regUserProjects('duration')} />
                </div>
            </div>
            <div className="col-12">
                <div className="inputField">
                    <label htmlFor="">Enter Details</label>
                    <textarea defaultValue={details} name="details" id="" cols="30" rows="3" className="form-control" placeholder="Add a paragraph saperated by '.'" {...regUserProjects('details')}></textarea>
                </div>
            </div>
            <div className="col-12">
                <div className="inputField">
                    <label htmlFor="">Add Technology used</label>
                    <div className="addProfileBox">
                        <input type="text" defaultValue={technology} name="technology" placeholder="saperate with comma (,)" className="form-control" {...regUserProjects('technology')} />
                    </div>
                </div>
            </div>
        </div>
        <div className="row mt-3">
            <div className="col-12">
                <button className="btn btn-theme me-3" type="submit">Update</button>
                <button className="btn btn-danger" onClick={() => handleDelete(id)}>Delete</button>
            </div>
        </div>
    </form>
}