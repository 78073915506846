import logo from "../../assets/logo.svg";
import google from "../../assets/google.svg";
import apple from "../../assets/apple.svg";
import "./Signin.scss";
import { NavLink } from "react-router-dom";
import { GoogleLogin } from 'react-google-login';

// const clientId = "30932067474-1mjm6dbmktmuqasu329umipsindiduu3.apps.googleusercontent.com";

export const Signin = () => {

    // const onSuccess = (res) => {
    //     console.log("Login Success! Current User:", res.profileObj);
    // }
    // const onFailure = (res) => {
    //     console.log("Login Failed:", res);
    // }

    return <section className="signin">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 text-center">
                    <img src={logo} alt="" className="logo" />
                </div>
                <div className="col-md-8 mx-auto authForm mt-5">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="">
                                <div className="loginForm">
                                    <h3 className="mb-4">Lets Start</h3>
                                    {/* <div className="emailBox">
                                        <input type="text" className="form-control" placeholder="Enter Email " />
                                    </div> */}
                                    {/* <NavLink to="/prompt"><button className="btn btn-primary px-4 my-4 d-block mx-auto">Proceed</button></NavLink> */}
                                    {/* <h3 className="mb-4">or</h3> */}
                                    <div className="text-center buttonBox">

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 avtar">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

}