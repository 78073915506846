import right from "../../assets/rightCircular.svg";
import "./Prompt.scss";
import { Header } from "../common/header/Header";
import { PromptBar } from "../common/promptBar/PromptBar";
import { NavLink } from "react-router-dom";
import { Loading } from "../Errors/Loading";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { PromptInstruction } from "../Errors/PromptInstruction";
const baseURL = process.env.REACT_APP_BASEURL;

export const PromptPanel = () => {
  // const { isPending, data } = useQuery({
  //     queryKey: ['candidates'],
  //     queryFn: logMovies,
  // })

  // async function logMovies() {
  //     let response;
  //     try {
  //         response = await fetch(`${baseURL}/candidates`, {
  //             headers: {
  //                 "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
  //             },
  //         });
  //         const candidateData = await response.json();
  //         // setAllCandidate(candidateData);
  //         console.log(candidateData);
  //         return candidateData;
  //     }
  //     catch (error) {
  //         Promise.reject(new Error("API went Wrong"))
  //         console.log(error, response)
  //     }
  // }

  const [promptResponse, setPromptResponse] = useState([]);

  const {
    mutate: Prompt,
    isPending,
    data,
  } = useMutation({
    mutationFn: async (data) => {
      return (
        await fetch(`${baseURL}/fetchCandidates`, {
          method: "POST",
          body: { ...data, promptResponse },
          headers: {
            "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
            "Content-Type": "application/json",
          },
        })
      ).json();
    },
  });

  return (
    <section className="promptPanel">
      <Header />
      <PromptBar Prompt={Prompt} />
      <div className="searchResults">
        <div className="row">
          <div className="col-12">
            <div className="row">
              {isPending ? (
                <Loading />
              ) : !data ? (
                <PromptInstruction />
              ) : (
                data?.content?.map((e) => {
                  return (
                    <div
                      key={e.id}
                      className="col-xxl-3 col-lg-4 col-sm-6 mt-4"
                    >
                      <div className="resultData">
                        <div className="image">
                          <img src={e.profilePic} alt="" />
                        </div>
                        <div className="content">
                          <div className="profileDetail">
                            <div className="flex-1">
                              <h5>{e.name}</h5>
                              <h6>{e.designation}</h6>
                            </div>
                            <div className="experience">
                              <h3 className="mb-0">{e.experience}Y</h3>
                            </div>
                          </div>
                          <div className="mt-2">
                            <p>{e.introduction}</p>
                          </div>
                          <div className="text-end">
                            <NavLink to={`/details/${e.id}`}>
                              Check profile <img src={right} alt="" />
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
