// src/components/Homepage.jsx
import React, { useContext } from 'react';
import logo from "../../assets/logo.svg";
import right from "../../assets/rightOutlineCircleDark.svg";
import curve from "../../assets/curve.svg";
import resumeIcon from "../../assets/resumeIcon.svg";
import "./Homepage.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Loading } from "../Errors/Loading";
import google from "../../assets/google.svg";
import GoogleLogin from "react-google-login";
import { UserContext } from "../../context/UserContext";

// const clientId = "30932067474-1mjm6dbmktmuqasu329umipsindiduu3.apps.googleusercontent.com";
const clientId = "501671887563-ableki4g9q5pq8475pvddipf7pkclh62.apps.googleusercontent.com";

const baseURL = process.env.REACT_APP_BASEURL;

export const Homepage = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);

    const { isPending, data } = useQuery({
        queryKey: ['candidates'],
        queryFn: logMovies,
    });

    async function logMovies() {
        let response;
        try {
            response = await fetch(`${baseURL}/candidates/33,41,44,36`, {
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                },
            });
            const candidateData = await response.json();
            // console.log(candidateData);
            return candidateData;
        } catch (error) {
            console.log(error, response);
            return Promise.reject(new Error("API went Wrong"));
        }
    }

    const onSuccess = (res) => {
        // console.log("Login Success! Current User:", res.profileObj);
        setUser(res.profileObj);
        navigate('/prompt');
    };

    const onFailure = (res) => {
        console.log("Login Failed:", res);
    };

    return (
        <section className="homePage py-5">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 centerMid">
                        <div className="data">
                            <img src={logo} alt="" className="logo" />
                            <h1>Introducing first <span>AI</span> tool to find the best resource for you business.</h1>
                            <p>We hold the database of over 1 lakh resumes for your need</p>
                            <GoogleLogin
                                className="btn btn-light px-4"
                                clientId={clientId}
                                buttonText="Continue with google"
                                onSuccess={onSuccess}
                                onFailure={onFailure}
                                cookiePolicy={'single_host_origin'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <h2 className='text-center text-light developers'>Discover the outstanding developers in our network.</h2>
                    </div>
                </div>
                <div className="row">
                    {console.log("DATA", data)}

                    {isPending ? <Loading /> : data.map((items, index) => (
                        <div className="col-md-3 mt-4">
                            <div className="resumeCard" key={index}>
                                <img src={items?.profilePic} alt="" className="profile w-100" />
                                <div className="content">
                                    <div>
                                        <h4 className="name">{items?.name}</h4>
                                        <p>{items?.designation}</p>
                                        <p>{items?.experience} Year Experience</p>
                                    </div>
                                    <div className="viewResume">
                                        <p className="text-center mb-4">View <br /> Resume</p>
                                        <div className="resumeButton">
                                            <NavLink to={`/details/${items?.id}`}> <img src={resumeIcon} alt="" /></NavLink>
                                        </div>
                                        <img src={curve} alt="" className="curve" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* {isPending ? <Loading /> : data.filter(e => [33, 41, 44, 36].includes(e.id)).map((e) => (
                        <div key={e.id} className="col-xl-3 col-sm-6 mx-auto mt-4">
                            <div className="resumeCard">
                                <img src={e.profilePic} alt="" className="profile w-100" />
                                <div className="content">
                                    <div>
                                        <h4 className="name">{e.name}</h4>
                                        <p>{e.designation}</p>
                                        <p>{e.experience} Year Experience</p>
                                    </div>
                                    <div className="viewResume">
                                        <p className="text-center mb-4">View <br /> Resume</p>
                                        <div className="resumeButton">
                                            <NavLink to={`/details/${e.id}`}> <img src={resumeIcon} alt="" /></NavLink>
                                        </div>
                                        <img src={curve} alt="" className="curve" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))} */}
                </div>
            </div>
            {/* <div className="container mt-5">
                <div className="row">
                    <div className="col-12">
                        <h2 className='text-center text-light developers'>Discover the outstanding developers in our network.</h2>
                    </div>
                </div>
                <div className="row">
                    {isPending ? <Loading /> : data.filter(e => [33, 41, 44, 36].includes(e.id)).map((e) => (
                        <div key={e.id} className="col-xl-3 col-sm-6 mx-auto mt-4">
                            <div className="resumeCard">
                                <img src={e.profilePic} alt="" className="profile w-100" />
                                <div className="content">
                                    <div>
                                        <h4 className="name">{e.name}</h4>
                                        <p>{e.designation}</p>
                                        <p>{e.experience} Year Experience</p>
                                    </div>
                                    <div className="viewResume">
                                        <p className="text-center mb-4">View <br /> Resume</p>
                                        <div className="resumeButton">
                                            <NavLink to={`/details/${e.id}`}> <img src={resumeIcon} alt="" /></NavLink>
                                        </div>
                                        <img src={curve} alt="" className="curve" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> */}
        </section>
    );
};
