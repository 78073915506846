import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import { fetchWrapper } from "../../util";
import { onErrors } from "../../util";
const baseURL = process.env.REACT_APP_BASEURL;



export const EditTechnology = ({ id, header, technology }) => {

    const { register: regUserTechnology, handleSubmit: hSUserTechnology, reset: resetUserTechnology, formState: { errors: errorsUserTechnology } } = useForm();


    // Candidate Technology 
    const { mutate: mutateTechnology } = useMutation({
        mutationFn: (data4) => {
            return fetchWrapper(`${baseURL}/candidate-technology/${data4.id}`, {
                method: "PUT",
                body: JSON.stringify({ ...data4 }),
                headers: {
                    "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
                    "Content-Type": "application/json",
                },
            })
        },
        onSuccess: () => {
            toast.success("Updated Successfully")
        },
        onError: () => {
            toast.error("There is an error")
        },
    })

    const handleDelete = async (id) => {
        await fetch(`${baseURL}/candidate-technology/${id}`, {
            method: 'DELETE',
            headers: {
                "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
            },
        });
    };

    const onTechnologySubmit = (data4) => {
        // console.log(data4, "Tech");
        mutateTechnology(data4);
    };

    return <form key={id} onSubmit={hSUserTechnology(onTechnologySubmit, onErrors)}>
        <div className="techBox mt-4">
            <input type="text" name="id" className="form-control d-none" value={id} {...regUserTechnology('id')} />
            <div className="inputField">
                <label htmlFor="">Heading</label>
                <input type="text" name="header" defaultValue={header} className="form-control" placeholder="" {...regUserTechnology('header')} />
            </div>

            <div className="inputField mt-3">
                <label htmlFor="">Skills</label>
                <input type="text" name="technology" defaultValue={technology} className="form-control" placeholder="" {...regUserTechnology('technology')} />
            </div>
        </div>
        <button className="btn btn-theme mt-4 me-3" type="submit">Update</button>
        <button className="btn btn-danger mt-4" onClick={() => handleDelete(id)}>Delete</button>
    </form>
}