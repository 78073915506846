// src/components/Header.jsx
import React, { useContext } from 'react';
import logo from "../../../assets/logo.svg";
import logomobile from "../../../assets/logoIcon.svg";
import search from "../../../assets/search.svg";
import notification from "../../../assets/notificationBell.svg";
import profile from "../../../assets/ResumeProfile.png";
import "./Header.scss";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { GoogleLogout } from 'react-google-login';
import { UserContext } from "../../../context/UserContext";

// const clientId = "30932067474-1mjm6dbmktmuqasu329umipsindiduu3.apps.googleusercontent.com";
const clientId = "501671887563-ableki4g9q5pq8475pvddipf7pkclh62.apps.googleusercontent.com";

export const Header = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const onSuccess = () => {
        console.log("Log Out Successfully");
        setUser(null);
        navigate('/');
    };
    const userImageUrl = user?.imageUrl ? user.imageUrl : profile;
    return (
        <header>
            <div className="logo">
                <Link to="/" className='d-none d-sm-block'><img src={logo} alt="" /></Link>
                <Link to="/" className='d-block d-sm-none'><img src={logomobile} alt="" className='mobileIcon' /></Link>
            </div>
            <div className="search" id="search">
                <div className="profile">
                    {user ? (
                        <>
                            <img src={userImageUrl} alt={user.name} onError={(e) => e.target.src = profile} />
                            <p className='text-light mb-0'> {user.name}</p>
                            <div className="dropdown">
                                <button className="btn btn-light dropdown-toggle py-0 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">

                                </button>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li className='p-2'><NavLink to={"/prompt"}>Prompt Panel</NavLink></li>
                                    <li className="p-2"><NavLink to={"/upload"}>Upload Resume</NavLink></li>
                                    <li className="p-2"><NavLink to={"/resume-list"}>Resume List</NavLink></li>
                                    <li className="p-2">
                                        <GoogleLogout
                                            clientId={clientId}
                                            buttonText={"Logout"}
                                            onLogoutSuccess={onSuccess}
                                        />
                                    </li>
                                </ul>
                            </div>
                        </>
                    ) : (
                        <img src={profile} alt="default profile" />
                    )}
                </div>
            </div>
        </header>
    );
};
