import { Header } from "../common/header/Header";
import "./ContactUs.scss";
import contact from "../../assets/contact.png"
export const ContactUs = () => {
    return <section className="contact">
        <Header />
        <div className="container">
            <div className="row">
                <div className="col-md-6">
                    <form className="form">
                        <h2>Contact Now</h2>

                        <div className="inputField">
                            <label htmlFor="">Name</label>
                            <input type="text" placeholder="Enter Your Name" className="form-control" />
                        </div>
                        <div className="inputField">
                            <label htmlFor="">Email</label>
                            <input type="email" placeholder="Enter Your Email" className="form-control" />
                        </div>
                        <div className="inputField">
                            <label htmlFor="">Resume Code</label>
                            <input type="text" placeholder="Enter Resume Code" className="form-control" />
                        </div>
                        <div className="inputField">
                            <label htmlFor="">Enter your query</label>
                            <textarea name="" id="" cols="30" rows="5" placeholder="Enter your query" className="form-control"></textarea>
                        </div>
                        <button className="btn btn-theme w-100 mt-5">Send Now</button>
                    </form>
                </div>
                <div className="col-md-6 mt-5 mt-md-0">
                    <div className="contactDetaiils">
                        <h2>Please complete the form below, and our team will reach out to you regarding your inquiry.</h2>
                        <p>Any specific details or questions you have that will help us assist you more effectively.</p>
                        <img src={contact} alt="" className="w-100" />

                    </div>
                </div>
            </div>
        </div>
    </section>
}