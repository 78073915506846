import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import edit from "../../assets/edit.svg";
import delete_icon from "../../assets/delete.svg";
import { Header } from "../common/header/Header";
import "./ResumeList.scss";
import view from "../../assets/view.svg";
import { NavLink } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASEURL;

export const ResumeList = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;

  const { isPending, isLoading, data, refetch } = useQuery({
    queryKey: ["candidates", currentPage],
    queryFn: logMovies,
  });

  async function logMovies() {
    let response;
    try {
      response = await fetch(
        `${baseURL}/candidates?page=${currentPage}&size=${pageSize}`,
        {
          headers: {
            "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
          },
        }
      );
      const candidateData = await response.json();
      setTotalPages(candidateData.totalPages);
      return candidateData.content;
    } catch (error) {
      return Promise.reject(new Error("API went Wrong"));
    }
  }

  const handleDelete = async (id) => {
    try {
      const response1 = await fetch(`${baseURL}/candidate-summaries/${id}`, {
        method: "DELETE",
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
        },
      });

      if (!response1.ok) {
        throw new Error(
          "Failed to delete candidate from 'candidate-summaries' API"
        );
      }

      const response2 = await fetch(`${baseURL}/candidates/${id}`, {
        method: "DELETE",
        headers: {
          "X-API-KEY": "TkqnmkYIEqwhcengzJynu",
        },
      });

      if (!response2.ok) {
        throw new Error("Failed to delete candidate from 'candidates' API");
      }

      await refetch();
    } catch (error) {
      console.error("Delete request failed:", error);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <section className="resumeList">
      <Header />
      <div className="container">
        <div className="row">
          <div className="col-12 mt-5">
            <table className="table mt-5">
              <thead>
                <tr>
                  <th scope="col">Resume ID</th>
                  <th scope="col">Image</th>
                  <th scope="col">Name</th>
                  <th scope="col">Designation</th>
                  <th scope="col" className="text-center">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {isPending ? (
                  <tr>
                    <td colSpan="5">
                      <div
                        style={{
                          width: "100%",
                          textAlign: "center",
                          position: "relative",
                          margin: '150px auto'
                        }}
                      >
                        <iframe src="https://lottie.host/embed/b20f982d-0676-43ff-9097-df06cfc3114d/mhPKWItxT3.json"></iframe>
                      </div>
                    </td>
                  </tr>
                ) : (
                  data.map((e) => {
                    return (
                      <tr key={e.id}>
                        <td>{e.id}</td>
                        <td>
                          <img src={e.profilePic} alt="" className="profile" />
                        </td>
                        <td>{e.name}</td>
                        <td>{e.designation}</td>
                        <td>
                          <div className="actionBox">
                            <NavLink to={`/details/${e.id}`}>
                              <img src={view} alt="" />
                            </NavLink>
                            <NavLink to={`/edit-resume/${e.id}`}>
                              <img src={edit} alt="" />
                            </NavLink>
                            {isLoading ? (
                              <span>Loading...</span>
                            ) : (
                              <img
                                src={delete_icon}
                                alt="Delete"
                                onClick={() => handleDelete(e.id)}
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div className="pagination centerMid gap py-3">
              <button
                className="btn btn-main"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 0}
              >
                Previous
              </button>
              <span>
                Page {currentPage + 1} of {totalPages}
              </span>
              <button
                className="btn btn-main"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage + 1 >= totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
