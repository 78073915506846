import magic from "../../../assets/magic.svg";
import { useForm } from "react-hook-form";
import { useRef } from "react";

export const PromptBar = ({ Prompt }) => {


    const { register: promptData, handleSubmit: promptSubmit } = useForm();
    const promptRef = useRef();
    const onPromptSubmit = (promptData) => {
        Prompt(promptData);
    };

    const onErrors = (errors) => {
        console.error(errors);
    };

    const toggleSidebar = () => {
        promptRef.current.classList.toggle('sidebar-expand');
    }
    return <div className="sideProbptBox" ref={promptRef}>
        <div className="sidebarHideShow" onClick={toggleSidebar}></div>
        <p>Describe your need in detail</p>
        <form onSubmit={promptSubmit(onPromptSubmit, onErrors)}>
            <textarea className="form-control" name="promptData" id="" cols="30" rows="7" {...promptData('promptData')} placeholder="I need a front end developer who has experience of at least 4 years." ></textarea>
            <button className="btn btn-light mt-4" type="submit">
                Generate Results <img src={magic} alt="" className="ms-4" />
            </button>
        </form>
    </div>
}